export const getHeaderActive = (data: any, name: string) => {
  const normalizedName = name?.toLowerCase();
  const pathName = window.location.pathname;
  const path =
    pathName?.includes('menu') || pathName?.includes('deals')
      ? '#menu'
      : !pathName?.includes('menu') && pathName?.includes('dispensaries')
      ? '#change_dispensary'
      : pathName;

  const replaceDynamicUrl = (url: string) => url.replace('{{url}}', window.location.origin);

  const navItem = data?.find((item: any) => item.navigationLabel.toLowerCase() === normalizedName);

  if (!navItem) return false;

  const isPathMatching = (url: string) => path?.includes(replaceDynamicUrl(url).replace(window.location.origin, ''));

  if (navItem.childNavigationCollection.items.length === 0) {
    return isPathMatching(navItem.navigationUrl);
  } else {
    return navItem?.childNavigationCollection?.items?.some((child: any) => isPathMatching(child.navigationUrl));
  }
};
