import { useDispatch } from 'react-redux';
import SearchBar from 'components/organisms/search/search-result/search';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { useAppSelector } from 'redux/hooks';
import { closeSearchBar } from 'redux/reducers/search';

interface IHeaderSearchProps {
  toggleModal: (data: any) => void;
  modal: any;
}

const HeaderSearch = ({ toggleModal, modal }: IHeaderSearchProps) => {
  const searchBarState = useAppSelector((state) => state?.search?.data?.searchBar);
  const dispatch = useDispatch();

  const handleClose = () => {
    if (searchBarState?.isOpen) {
      dispatch(closeSearchBar());
    } else {
      toggleModal(THeaderState.SEARCHBAR);
    }
  };
  return (modal?.searchBar?.isOpen || searchBarState?.isOpen) && <SearchBar onClose={handleClose} />;
};

export default HeaderSearch;
