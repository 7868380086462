import HeroCarousel from 'components/organisms/heros/hero-carousel/index';

const HeroCarouselTastics = (props: any) => {
  const { data, pageFolder } = props;
  const heroCarouselSectionCollection =
    data.heroCarousel?.dataSource?.data?.page?.items?.[0].heroCarouselSectionCollection;
  const homeSliderData = heroCarouselSectionCollection?.items || [];
  return (
    <HeroCarousel
      homeSliderData={homeSliderData.filter((item) => item !== null)}
      placement={data?.tasticPlacementNumber}
      host={pageFolder?.host}
    />
  );
};

export default HeroCarouselTastics;
