import NavItem from 'components/atoms/navigation/nav-item/nav-item';
import { navigationTrack } from 'helpers/analytics/navigation-track';
import { SegmentEvents } from 'helpers/constants/forms';
import { THeaderState } from 'helpers/types/organisms/global/header';
import { ReplaceUrlWithHost, getLinkTarget, isLITH } from 'helpers/utils/common';
import { getHeaderActive } from 'helpers/utils/header';
import CloseIcon from 'public/icons/figma/close-small.svg';
import MenuIcon from 'public/icons/figma/Menu.svg';
import SearchBigIcon from 'public/icons/figma/search-big.svg';
import { useAppSelector } from 'redux/hooks';
import { TInitialStateProps } from '../..';
import { HeaderIconBox, HeaderIconMobileCard, HeaderLinkBox, HeaderLinkWrapper } from './styles';

type TProps = {
  data: any;
  modal: TInitialStateProps;
  variant?: 'dark' | 'light';
  closeModal: () => void;
  toggleModal: (title: string, data?: any) => void;
  handleClick: () => void;
};

const HeaderLink = ({ data, variant, toggleModal, modal, closeModal, handleClick }: TProps) => {
  const host = useAppSelector((state) => state.hostReducer.host);

  const handleNavItem = (name: string, data?: any) => {
    switch (name) {
      case 'shop':
        return (
          !modal?.shop?.isOpen &&
            navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
              nav_position: 'header',
              link_primary: name,
              link_type: 'dropdown',
            }),
          toggleModal(THeaderState.SHOP, data)
        );
      case 'dispensaries':
        if (modal?.changeDispensary?.isOpen) {
          return toggleModal(THeaderState.CHANGEDDISPENSARY);
        } else {
          return (
            !modal?.dispensaries?.isOpen &&
              navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
                nav_position: 'header',
                link_primary: name,
                link_type: 'dropdown',
              }),
            toggleModal(THeaderState.DISPENSARIES)
          );
        }
      case 'cannabis insider':
      case 'resources':
        return (
          !modal?.cannabisInsider?.isOpen &&
            navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
              nav_position: 'header',
              link_primary: name,
              link_type: 'dropdown',
            }),
          toggleModal(THeaderState.CANNABISINSIDER, { name, data })
        );
      case 'rewards':
        return navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
          nav_position: 'header',
          link_primary: name,
          link_type: 'hyperlink',
        });
    }
  };
  const handleMobileClick = () => {
    if (modal?.extendedMenu) {
      closeModal();
    } else {
      navigationTrack(SegmentEvents.NAVIGATION_CLICKED, {
        nav_position: 'header',
        link_primary: 'hamburger',
        link_type: 'hamburger',
      });
      toggleModal('navbar');
    }
  };

  return (
    <HeaderLinkWrapper>
      <HeaderLinkBox>
        {data &&
          data?.map((item, index) => (
            <li key={index}>
              <NavItem
                onClick={() =>
                  handleNavItem(item?.navigationLabel.toLowerCase(), item?.childNavigationCollection?.items)
                }
                variant={variant}
                href={ReplaceUrlWithHost(item?.navigationUrl, host)}
                className="uppercase"
                target={getLinkTarget(ReplaceUrlWithHost(item?.navigationUrl, host))}
                type={item?.navigationUrl?.includes('#') ? 'dropdown' : 'simple'}
                isDropDownOpen={
                  item?.navigationLabel?.toLowerCase() === 'shop'
                    ? modal?.shop?.isOpen
                    : item?.navigationLabel?.toLowerCase() === 'dispensaries'
                    ? modal?.dispensaries?.isOpen || modal?.changeDispensary?.isOpen
                    : item?.navigationLabel?.toLowerCase() === 'cannabis insider' ||
                      item?.navigationLabel?.toLowerCase() === 'resources'
                    ? modal.cannabisInsider.isOpen
                    : null
                }
                isActive={getHeaderActive(data, item?.navigationLabel)}
              >
                {isLITH() && item?.navigationLabel?.toLowerCase() === 'cannabis insider'
                  ? 'Resources'
                  : item?.navigationLabel}
              </NavItem>
            </li>
          ))}
      </HeaderLinkBox>
      <HeaderIconMobileCard>
        <HeaderIconBox data-testid={'extended-menu-button'} $variant={variant} onClick={handleMobileClick}>
          {!modal.extendedMenu || modal.searchBar.isOpen ? (
            <MenuIcon className="h-6 w-6" />
          ) : (
            <CloseIcon className="text-white" />
          )}
        </HeaderIconBox>
        <HeaderIconBox $variant={variant} onClick={handleClick}>
          <SearchBigIcon size={'1.5em'} />
        </HeaderIconBox>
      </HeaderIconMobileCard>
    </HeaderLinkWrapper>
  );
};

export default HeaderLink;
